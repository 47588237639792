body {
    background-color: #060a1e;
    color: #fff;
}

#main-no-access {
    color: #fff;
}

#main {
    color: #fff;
}

// SLP Price
#slp {
    width: 40px;
    height: 40px;
}

// Top Stats Section
.stat-box {
    margin: 10px;
    padding: 10px;
    text-align: center;
    background-color: #0dcaf0 !important;
    color: rgb(33, 37, 41);
}
.stat-box .title {
    font-size: 13px;
    padding-top: 8px;
    padding-bottom: 10px;
}
.stat-box .value {
    font-size: 20px;
}
.stat-box .value .nameList {
    font-size: 14px;
}
.dollars {
    color:greenyellow;
    font-size: 16px;
}

#lastUpdatedAt-container {
    color: #c5c5c5;
}

// Spinner
#spinner {
    display: none;
    font-size: 28px;
}

// Scholar Input Form
#roninAddress {
    min-width: 415px;
}
#addButton {
    margin-top: 2rem !important;
}

// Scholar List Table
.table, 
.table-striped > tbody > tr {
   color: #FFF !important;
}

table td {
    vertical-align: middle;
}

table tr.claimReadyRow td {
    background-color: #36bb2d !important;
    font-weight: bold;
    color: #000 !important;
    box-shadow: none !important;
}

.ready-now {
    font-weight: bold;
    color: #9a1723;
}

table.leaderboard {
    margin-top: 25px;

    tr td {
        height: 55px;
    }
}

